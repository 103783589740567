@media only screen and (max-width: 1024px) {
    /* For ipad pro : */
    .contactFlex{
        flex-direction: column;
    }
    
  }
  @media (max-width: 768px) {
    .contactFlex {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; 
    }
    .contactFlex > * {
      margin-bottom: 20px; 
    }
    .contactFlex .contact{
        font-size: 50px;
        margin-left: 25px;
    }
               
  }
  

