
.button {
    border:2px solid #bfbfbf;
    border-left: 5px solid black;
    display: block;
    background: linear-gradient(to right, black 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.button:hover {
    background-position: left bottom;
}

.img-container {
    width: 272px;
    aspect-ratio: 1;
}

