.contacts{
    position: absolute;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  ------------
  .contact{
    position:relative;
  }
  
  .contact.one3 {
    grid-column: 10;
    grid-row: 1;
    rotate: 180deg;
    
  }
  .footer.two4 {
    grid-column: 4;  
    grid-row: 10;
    
  }