:root {
  --clr-neutral-900: hsl(207, 19%, 9%);
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-accent-400: #EF3B3B;
}

// *::before,
// *::after {
//   box-sizing: border-box;
// }
.card {
  color: var(--clr-neutral-100);
  background-image: url(https://media.contentapi.ea.com/content/dam/eacom/images/2020/09/ea-featured-image-ea-desktop-beta.jpg.adapt.crop191x100.1200w.jpg);
  background-size: cover;
  padding: 8rem 0 0;
  max-width: 30ch;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 500ms ease;
}

.card:hover,
.card:focus-within {
  transform: scale(1.05);
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.6) 20%,
    hsl(0 0% 0% / 1)
  );
}

.card-title {
  position: relative;
  width: max-content;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
  transform: scaleX(1);
}

.card-title::after {
  content: "";
  position: absolute;
  height: 4px;
  width: calc(100% + var(--padding));
  left: calc(var(--padding) * -1);
  bottom: -2px;
  background: var(--clr-accent-400);
  transform-origin: left;
  transition: transform 500ms ease;
}

@media (hover) {
  .card-content {
    transform: translateY(65%);
    transition: transform 500ms ease;
  }

  .card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 500ms linear;
  }

  .card:hover .card-content > *:not(.card-title),
  .card:focus-within .card-content > *:not(.card-title) {
    opacity: 1;
    transition-delay: 700ms;
  }

  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(0);
    transition-delay: 500ms;
  }

  .card:focus-within .card-content {
    transition-duration: 0ms;
  }

  .card-title::after {
    transform: scaleX(0);
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: var(--clr-neutral-900);
  background-color: var(--clr-accent-400);
  padding: 0.5em 1.25em;
  border-radius: 0.25rem;
}

.button:hover,
.button:focus {
  background-color: var(--clr-neutral-100);
}

.card-body {
  color: rgb(255, 255, 255/0.85);
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}

@media (max-width: 768px) {
  .customClassName {
    text-align: center;
    font-size: 18px;
  }         
}

@media (max-width: 768px) {
  .flex-item {
    flex-basis: calc(50% - 20px); 
    margin-bottom: 20px; 
    padding: 4px;
    font-size: 14px;
    text-align: center;
  }
}
.card-overlay {
  position: absolute;
  inset: 0;
  background-color: #000000de;
  opacity: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 0;
  transition: opacity 0.3s ease, height 0.5s ease; /* Add height transition */
}

.card:hover .card-overlay {
  opacity: 0.75;
  height: 100%; /* Change height to 100% on hover */
}
.icon.rotate {
  transition: transform 0.4s linear; /* Adjust the duration to make it faster */
  cursor: pointer;
}

.icon.rotate:hover {
  transform: rotate(360deg); /* Rotate a full cycle */
}
.ant-modal .ant-modal-content {
  background-color: rgba(35, 35, 35, 0.96);
}
.ant-modal .ant-modal-close-x {
  color: #fff;
  font-size: x-large;
}


@media (max-width: 768px) {
  .customClassName {
    text-align: center;
    font-size: 10px;
  }
  
             
}