.btn {
    border:2px solid #bfbfbf;
    display: block;
    background: linear-gradient(to right, black 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.btn:hover {
    background-position: left bottom;
}


@media (max-width: 768px) {
    .corvanta-logo img {
      height: auto;
    }
  }