// .home-background {
//   background-color: #000000;
//   opacity: 1;
//   background-image:  linear-gradient(#7a7a7a 0.2px, transparent 0.5px), linear-gradient(to right, #7a7a7a 0.2px, #000000 0.5px);
//   background-size: 250px 250px;
// }
/* Default font size for Corvanta font */

.title {
  font-family: "Cairo";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  letter-spacing: 1px;
  line-height:2
}

.text-animation-corvanta {
  background-image: url(https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcnFjN2s5cWpubmlzNjFpbjU4amh0YjdybnUwMWNmaGlsdHlmcWt1ZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/QBj6FFNpXGYq8L8b3b/giphy-downsized-large.gif);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  margin: 10px 0;
}


.Corvanta-font {
  font-size: 250px;
}

/* Media query for small screens */
@media only screen and (max-width: 600px) {
  .Corvanta-font {
    font-size: 70px;
  }
}

/* Media query for medium-sized screens */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .Corvanta-font {
    font-size: 100px;
  }
}

/* Media query for large screens */
@media only screen and (min-width: 1025px) {
  .Corvanta-font {
    font-size: 220px;
  }
}


.normal-text {
  font-family: "Epilogue", sans-serif;
}

.banner {
  width: 100%;
  overflow: hidden;
}

.background {
  display: flex;
  animation: move 15s linear infinite;
  white-space: nowrap;
  /* Prevent items from wrapping */
}

.item {
  flex: 0 0 auto;
  text-align: center;
  margin-right: 100px;
  /* Add some margin between items */
}

@keyframes move {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}



.horizontal-line {
  position: fixed;
  top: 0;
  left: 0;
  height: 55vh;
  width: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}

.menu-item {
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s ease;
}
// .menu-item:hover{
//   transition: 2s;
//   scale: 20;

// }
.line {
    border-top: 1px solid #EF3B3B ;
  // border-top: 3px solid #EF3B3B;
  width: 45px;
  /* Adjust this width as needed */
  position: absolute;
  left: 0;
  transition: width 0.3s ease;
  /* Add transition for width */
}

.menu-item-name {
  position: absolute;
  top: calc(100% + 5px);
  /* Position text below the line */
  left: 50%;
  /* Center text horizontally */
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 18px;
  // color: #EF3B3B;
  opacity: 0;
  /* Initially hide the name */
  transition: opacity 0.3s ease;
}

// .menu-item-name-even {
//   position: absolute;
//   top: calc(100% + 5px);
//   /* Position text below the line */
//   left: 50%;
//   /* Center text horizontally */
//   transform: translateX(-50%);
//   font-size: 18px;
//   color: #000000;
//   opacity: 0;
//   /* Initially hide the name */
//   transition: opacity 0.3s ease;
// }

.menu-item:hover .line {
  border-top: 4px solid #EF3B3B;
  width: 80px;
  /* Adjust expanded width as needed */
  
  transition: width 0.3s ease;
  position: absolute;
}

.menu-item.active{
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.menu-item:hover .menu-item-name {
  opacity: 1;
  /* Show the name on hover */
}

.menu-item.active .line {
  border-top: 4px solid #EF3B3B;
  width: 80px;
  /* Adjust expanded width as needed */
  transition: width 0.3s ease;
  position: absolute;
}

.menu-item.active .menu-item-name {
  opacity: 1;
  margin-bottom: 10px;
  /* Show the name on hover */
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 2);
  /* Adjust values as needed */
}

.text-shadow-home {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* Adjust values as needed */
}

@media (max-width: 768px) {
#home{
  font-size: 24px;
}
.home{
  font-size: 44px;
  top:10px;
}
.vision{
  font-size: 18px;
}
.corvanta-text{
  font-size: 60px;
  margin-bottom: 0px;
}
}