.swiper-container {
  border-radius: 15px;
  width: 70vw;
  overflow: hidden;
  height: fit-content;
  height: fit-content;
  font-size: 50px;
  position: relative;
  padding-bottom: 50px;
  font-family: sans-serif;
}

.swiper-slide {
  width: auto;
  height: fit-content;
  display: flex;
  height: auto;
  border-radius: 15px;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px;
  font-family: Nunito;
  background-color: white;

}

.swiper-slide .ImgHolder {
  background-color: #282828;
  display: flex;
  width: 100%;
  height: auto;
  padding: 30px;
  border-bottom-left-radius: 20px;

}

.ContentHolder::before {
  background-color: #282828;
  content: '';
  width: 50px;
  height: 50px;
  position: absolute;
  top: -5px;
  z-index: -1;
  right: 0px;
}

.ContentHolder {
  position: relative;
  padding: 10px;
  background-color: white;
  border-top-right-radius: 20px;
}

.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
  border: 1px solid #282828;
}

.swiper-pagination-bullet-active {
  background-color: #EF3B3B;
}

.swiper-button {
  border: 1px solid white;
}

.swiper-slide img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  object-fit: cover;
  border: 3px solid #282828;
  outline: 2px solid white;
}

.swiper-slide h3 {
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
}

.swiper-slide p {
  font-size: 0.9rem;
  padding: 5px;
}

@media (max-width:638px) {
  .swiper-container {
    width: 100%;
  }
}

@media (max-width:500px) {
  .swiper-container {
    width: 70vw;
  }
}

@media (max-width:300px) {
  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    border-radius: 0px;
  }

  .swiper-container .ImgHolder {
    border-radius: 0px;
  }
  .ContentHolder{
    border-radius: 0px;
  }
}